import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"

import Blog2 from "../components/img/blog/2"

import B2_2 from "../components/img/blog/2/22"
import B2_3 from "../components/img/blog/2/23"
import B2_4 from "../components/img/blog/2/24"
import B2_5 from "../components/img/blog/2/25"
import B2_6 from "../components/img/blog/2/26"
import B2_7 from "../components/img/blog/2/27"

import GifPath from "/src/images/blog/korona/GIF_covid19_zemepisec.gif"

import SEO from "../components/seo"

export default ({ data }) => (
  <Layout>
    <SEO title="Vyhledávání koronaviru na Google: Časosběrná mapa, grafy a data" />
    <h1>Vyhledávání koronaviru na Google: Časosběrná mapa, grafy a data</h1>
    <Blog2/>
    <br />

    <p>Koronavirus h&yacute;be s&iacute;těmi. Ukažme si na časosběrn&eacute; mapě, jak se měnil z&aacute;jem o vyhled&aacute;v&aacute;n&iacute; koronaviru na Googlu např&iacute;č evropsk&yacute;mi zeměmi. Mapu doprov&aacute;z&iacute; grafy v&yacute;voje z&aacute;jmu v nejv&iacute;ce postižen&yacute;ch zem&iacute;ch, zem&iacute;ch V4 (Česko, Polsko, Slovensko, Maďarsko) a jin&yacute;ch vybran&yacute;ch skupin&aacute;ch.</p>
    <hr /><h2>Časosběrn&aacute; mapa vyhled&aacute;v&aacute;n&iacute; koronaviru</h2>
    <p><strong>Časosběrn&aacute; mapa vyhled&aacute;v&aacute;n&iacute; koronaviru ve vyhled&aacute;vači Google</strong> ve vybran&yacute;ch evropsk&yacute;ch st&aacute;tech <strong>mezi 21.&nbsp;1.&nbsp;2020&nbsp;a&nbsp;24.&nbsp;3.&nbsp;2020</strong>, tedy v prvn&iacute;ch t&yacute;dnech &scaron;&iacute;řen&iacute; pandemie v Evropě:</p>
    <img src={GifPath} alt="Animovaná mapa zájmu o hledání koronaviru na vyhledávači Google" />
    <h3>Co znamen&aacute; "z&aacute;jem o hled&aacute;n&iacute;" v tomto kontextu?</h3>
    <p>Z&aacute;jem o hled&aacute;n&iacute; je metrika Googlu vyjadřuj&iacute;c&iacute; <strong>relativn&iacute; z&aacute;jem</strong> ve vyhled&aacute;v&aacute;n&iacute; vzhledem k&nbsp;nejvy&scaron;&scaron;&iacute;mu bodu grafu pro danou oblast a&nbsp;dobu. Hodnota 100 představuje nejvy&scaron;&scaron;&iacute; popularitu v&yacute;razu. Hodnota 50 znamen&aacute;, že měl v&yacute;raz polovičn&iacute; popularitu.</p>
    <hr /><h2>Prvn&iacute; t&yacute;dny pandemie COVID-19 a Google hled&aacute;n&iacute;</h2>
    <p>Dne 24. 1. 2020 byl <a href="https://www.eurosurveillance.org/content/10.2807/1560-7917.ES.2020.25.9.2000178">potvrzen prvn&iacute; př&iacute;pad n&aacute;kazy koronavirem</a> v Evropě. V souvislosti s t&iacute;m lze v několika n&aacute;sleduj&iacute;c&iacute;ch dnech v mapě pozorovat <strong>prvn&iacute; zv&yacute;&scaron;en&yacute; z&aacute;jem o hled&aacute;n&iacute; na t&eacute;ma koronavirus</strong>. <strong>Nejv&iacute;ce</strong> pak v<strong> It&aacute;lii (22), Maďarsku (13), Spojen&eacute;m kr&aacute;lovstv&iacute; (12) </strong>a <strong>Česk&eacute; republice (11)</strong>.</p>
    <p>Z&aacute;jem pak v obdob&iacute; mezi 2. a 20. &uacute;norem kles&aacute;. <strong>Ke zlomu</strong> doch&aacute;z&iacute; <strong>21.&nbsp;&uacute;nora v It&aacute;lii</strong>. V souvislosti s v&yacute;skytem n&aacute;kazy v Lombardii vyrostou počty hled&aacute;n&iacute; na Googlu raketov&yacute;m tempem během 3 dnů, a to z hodnoty 2 (20. 2. 2020) na hodnotu maxim&aacute;n&iacute;, tedy 100 (23.&nbsp;2.&nbsp;2020).</p>
    <p>Z&aacute;jem o hled&aacute;n&iacute; informac&iacute; na t&eacute;ma COVID-19 pak roste v&scaron;ude Evropě a kulminuje ve vět&scaron;ině zem&iacute; kolem 12.&nbsp;3. <strong>V&yacute;voj se v</strong><strong>různ&yacute;ch zem&iacute; ale li&scaron;&iacute;</strong> a <strong>bl&iacute;že je rozebr&aacute;n pod detailněj&scaron;&iacute;mi grafy </strong>v&yacute;voje hled&aacute;n&iacute;.</p>
    <h3>Nejv&iacute;ce postižen&eacute; evropsk&eacute; země</h3>
    <B2_2/>
    <p>V It&aacute;lii byl průměrn&yacute; z&aacute;jem o hled&aacute;n&iacute; nejvy&scaron;&scaron;&iacute; ze v&scaron;ech sledovan&yacute;ch evropsk&yacute;ch zem&iacute;. Maxim&aacute;ln&iacute; hodnoty (100) dos&aacute;hl 23.&nbsp;2.&nbsp;2020, kdy do&scaron;lo do t&eacute; doby k bezprecedentn&iacute;mu <a href="https://ct24.ceskatelevize.cz/svet/3052872-cina-hlasi-dalsich-sto-padesat-obeti-koronaviru-rakousko-obnovilo-zeleznicni-spojeni-s">uzavřen&iacute; prvn&iacute;ch italsk&yacute;ch obc&iacute; do karant&eacute;ny</a></p>
    <p>V souvislosti s roz&scaron;&iacute;řen&iacute;m n&aacute;kazy COVID-19 a nově ustanoven&yacute;ch opatřen&iacute; pak po 11. březnu roste intenzita hled&aacute;n&iacute; ve &Scaron;panělsku i Francii. Obě země pak v z&aacute;jmu hled&aacute;n&iacute; předstihuj&iacute; It&aacute;lii (Francie pouze po několik dn&iacute;).</p>
    <p>V Německu, ačkoliv jde o jednu z nejv&iacute;ce postižen&yacute;ch evropsk&yacute;ch zem&iacute;, je z&aacute;jem relativně mal&yacute;. Nav&iacute;c vrchol&iacute; oproti ostatn&iacute;m zem&iacute;m o pozn&aacute;n&iacute; později, a to 22.&nbsp;března. Tehdy do&scaron;lo <a href="https://www.ceskenoviny.cz/zpravy/v-nemecku-budou-zakazana-shromazdeni-vice-nez-dvou-lidi/1869916">k vyhl&aacute;&scaron;en&iacute; př&iacute;sn&eacute;ho z&aacute;kazu shromažďov&aacute;n&iacute;.</a></p>
    <h3>Země V4 (Česko, Polsko, Slovensko, Maďarsko)</h3>
    <B2_3/>
    <p>Země Visegr&aacute;dsk&eacute; čtyřky (Česko, Polsko, Slovensko, Maďarsko) projevovaly podobn&yacute; průběh z&aacute;jmu o vyhled&aacute;v&aacute;n&iacute; koronaviru. Země vykazovaly průměrn&yacute; až nadprůměrn&yacute; z&aacute;jem. V době zač&aacute;tku krize na konci ledna pak nadprůměrn&yacute; (zejm&eacute;na pak Maďarsko a Česko).</p>
    <p>Zat&iacute;mco z&aacute;jem v Česku, Polsku a na Slovensku po kulminaci mezi 12. a 15. březnem (<a href="https://ct24.ceskatelevize.cz/domaci/3062610-v-cesku-je-214-potvrzenych-pripadu-nakazy-novym-koronavirem">vrchol&iacute;c&iacute; opatřen&iacute; vl&aacute;d</a>) relativně rychle opad&aacute;val, v Maďarsku klesal z&aacute;jem o hled&aacute;n&iacute; pozvolněji, což může souviset s <a href="https://www.irozhlas.cz/zpravy-svet/viktor-orban-madarsko-vladni-opatreni-kritika-evropska-rada-pandemie-koronavirus_2003261009_tzr">taměj&scaron;&iacute;mi kontroverzn&iacute;mi dekrety</a>, kter&eacute; byly vyd&aacute;ny maďarsk&yacute;m premi&eacute;rem Orb&aacute;nem.</p>
    <h3>Skandin&aacute;vsk&eacute; země</h3>
    <B2_4/>
    <p>V&yacute;voj z&aacute;jmu o vyhled&aacute;v&aacute;n&iacute; informac&iacute; souvisej&iacute;c&iacute; s pandemi&iacute; COVID-19 ve Skandin&aacute;vsk&yacute;ch zem&iacute;ch (Norsko, D&aacute;nsko, Finsko, &Scaron;v&eacute;dsko) byl prakticky shodn&yacute;. A to i přes rozd&iacute;ln&yacute; př&iacute;stup k boji s koronavirem. Zat&iacute;mco např&iacute;klad Norsko přijalo <a href="https://www.lifeinnorway.net/norway-is-closed-coronavirus/">relativně trvd&aacute; opatřen&iacute;</a>, ve &Scaron;v&eacute;dsku tomu tak <a href="https://www.dw.com/en/coronavirus-no-lockdowns-in-sweden-for-now/a-52904188">k 24. března nebylo</a>. Z&aacute;jem je v porovn&aacute;n&iacute; s ostatn&iacute;mi zeměmi velmi mal&yacute;.</p>
    <h3>Velk&aacute; Brit&aacute;nie, Nizozem&iacute; a Belgie</h3>
    <B2_5/>
    <p>Průběh z&aacute;jmu o hled&aacute;n&iacute; koronaviru je Spojen&eacute;m kr&aacute;lovstv&iacute;, Irsku, Nizozem&iacute; i Belgii podobn&yacute;, li&scaron;&iacute; se v&scaron;ak intenzitou. Zat&iacute;mco v Nizozemsku je z&aacute;jem mal&yacute;, na &uacute;rovni zem&iacute; ve Skandin&aacute;vii, v Brit&aacute;nii je z&aacute;jem velk&yacute;.</p>
    <p>Zaj&iacute;mav&yacute; je zdvojen&yacute; vrcholek z&aacute;jmu. Kolem 11. března Spojen&yacute;m kr&aacute;lovstv&iacute;m rezonovala zejm&eacute;na opatřen&iacute;, kter&aacute; <a href="https://www.bbc.com/news/world-51737226">byla přijata v ostatn&iacute;ch evropsk&yacute;ch st&aacute;tech</a>. Spojen&eacute; kr&aacute;lovstv&iacute; v čele s premi&eacute;rem Borisem Johnsonem se br&aacute;nilo restrikc&iacute;m. Vl&aacute;dnouc&iacute; garnitura v&scaron;ak <a href="https://www.bbc.com/news/health-51915302">pod tlakem měn&iacute; 16. března kurz</a>, což se odr&aacute;ž&iacute; v druh&eacute;m vrcholku z&aacute;jmu o t&eacute;ma koronaviru.</p>
    <h3>Pobaltsk&eacute; země</h3>
    <B2_6/>
    <p>Pobaltsk&eacute; země (Loty&scaron;sko, Litva, Estonsko) se průběhem vyhled&aacute;v&aacute;n&iacute; podobaj&iacute; skandin&aacute;vsk&yacute;m zem&iacute;m. Vykazuj&iacute; v&scaron;ak je&scaron;tě o něco men&scaron;&iacute; relativn&iacute; m&iacute;ru z&aacute;jmu o hled&aacute;n&iacute; informac&iacute;. I zde plat&iacute; velmi <a href="https://www.businessinfo.cz/clanky/prevence-a-pragmatismus-aneb-jak-pristupuji-ke-coronaviru-staty-pobalti/">tvrd&aacute; opatřen&iacute;</a>.</p>
    <h3>Soused&eacute; It&aacute;lie</h3>
    <B2_7/>
    <p>Po prvotn&iacute;m &scaron;&iacute;řen&iacute; n&aacute;kazy v italsk&eacute; Lombardii doch&aacute;z&iacute; ke skokov&eacute;mu n&aacute;růstu z&aacute;jmu o hled&aacute;n&iacute; informac&iacute; souvisej&iacute;c&iacute; s n&aacute;kazou COVID-19 ve Slovinsku, Rakousku i &Scaron;v&yacute;carsku. N&aacute;růst v mnohem vět&scaron;&iacute; Francii je pozvolněj&scaron;&iacute;.</p>
    <p>Z&aacute;jem o vyhled&aacute;v&aacute;n&iacute; byl ve Francii i &Scaron;v&yacute;carsku od 28.&nbsp;2. po vět&scaron;inu sledovan&eacute;ho obdob&iacute; vy&scaron;&scaron;&iacute;, což pravděpodobně souvis&iacute; s <a href="https://www.worldometers.info/coronavirus/">relativně dř&iacute;věj&scaron;&iacute;m &scaron;&iacute;řen&iacute;m epidemie</a>. Intenzita hled&aacute;n&iacute; opět kulminuje v souvislosti se zav&aacute;děn&iacute;m různ&yacute;ch restrikc&iacute; po 11. březnu.</p>
    <h3>Souhrnn&aacute; tabulka v&scaron;ech sledovan&yacute;ch st&aacute;tů v Evropě</h3>
    <p>Ne na v&scaron;echny st&aacute;ty se dostalo, jednodu&scaron;e pro ně nen&iacute; již prostor. V grafech třeba nebylo zv&yacute;razněno Řecko, Portugalsko, Chorvatsko, Rumunsko a Bulharsko. Data o intenzitě vyhled&aacute;v&aacute;n&iacute; informac&iacute; na t&eacute;ma koronavirus v&scaron;ak lze naj&iacute;t v n&iacute;že uveden&eacute; souhrnn&eacute; tabulce, ve kter&eacute; jsou vyps&aacute;ny vybran&eacute; &uacute;daje za v&scaron;echny sledovan&eacute; st&aacute;ty.</p>
    <p>St&aacute;ty jsou seřazeny podle průměrn&eacute;ho z&aacute;jmu o hled&aacute;n&iacute; koronaviru ve vyhled&aacute;vači Google v obdob&iacute; mezi 21.1.2020 a 24.3.2020.</p>
    <figure class="wp-block-table">
    <table>
    <tbody>
    <tr>
    <td class="has-text-align-center" data->Pořad&iacute;</td>
    <td>Země</td>
    <td class="has-text-align-center" data->Průměr</td>
    <td class="has-text-align-center" data->Max</td>
    <td class="has-text-align-center" data->Dosažen&iacute; max</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->1</td>
    <td>It&aacute;lie</td>
    <td class="has-text-align-center" data->33</td>
    <td class="has-text-align-center" data->100</td>
    <td class="has-text-align-center" data->23.02.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->2</td>
    <td>&Scaron;panělsko</td>
    <td class="has-text-align-center" data->23</td>
    <td class="has-text-align-center" data->92</td>
    <td class="has-text-align-center" data->12.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->3</td>
    <td>Irsko</td>
    <td class="has-text-align-center" data->20</td>
    <td class="has-text-align-center" data->66</td>
    <td class="has-text-align-center" data->12.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->4</td>
    <td>Francie</td>
    <td class="has-text-align-center" data->19</td>
    <td class="has-text-align-center" data->73</td>
    <td class="has-text-align-center" data->15.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->5</td>
    <td>&Scaron;v&yacute;carsko</td>
    <td class="has-text-align-center" data->19</td>
    <td class="has-text-align-center" data->65</td>
    <td class="has-text-align-center" data->13.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->6</td>
    <td>Spojen&eacute; kr&aacute;lovstv&iacute;</td>
    <td class="has-text-align-center" data->18</td>
    <td class="has-text-align-center" data->66</td>
    <td class="has-text-align-center" data->16.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->7</td>
    <td>Maďarsko</td>
    <td class="has-text-align-center" data->15</td>
    <td class="has-text-align-center" data->56</td>
    <td class="has-text-align-center" data->16.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->8</td>
    <td>Protugalsko</td>
    <td class="has-text-align-center" data->14</td>
    <td class="has-text-align-center" data->55</td>
    <td class="has-text-align-center" data->12.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->9</td>
    <td>Rakousko</td>
    <td class="has-text-align-center" data->14</td>
    <td class="has-text-align-center" data->50</td>
    <td class="has-text-align-center" data->15.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->10</td>
    <td>Polsko</td>
    <td class="has-text-align-center" data->14</td>
    <td class="has-text-align-center" data->57</td>
    <td class="has-text-align-center" data->12.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->11</td>
    <td>Německo</td>
    <td class="has-text-align-center" data->13</td>
    <td class="has-text-align-center" data->43</td>
    <td class="has-text-align-center" data->22.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->12</td>
    <td>Rumunsko</td>
    <td class="has-text-align-center" data->13</td>
    <td class="has-text-align-center" data->51</td>
    <td class="has-text-align-center" data->14.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->13</td>
    <td>Slovensko</td>
    <td class="has-text-align-center" data->12</td>
    <td class="has-text-align-center" data->41</td>
    <td class="has-text-align-center" data->14.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->14</td>
    <td>Česko</td>
    <td class="has-text-align-center" data->12</td>
    <td class="has-text-align-center" data->44</td>
    <td class="has-text-align-center" data->15.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->15</td>
    <td>Belgie</td>
    <td class="has-text-align-center" data->11</td>
    <td class="has-text-align-center" data->43</td>
    <td class="has-text-align-center" data->12.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->16</td>
    <td>Slovinsko</td>
    <td class="has-text-align-center" data->10</td>
    <td class="has-text-align-center" data->39</td>
    <td class="has-text-align-center" data->12.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->17</td>
    <td>Řecko</td>
    <td class="has-text-align-center" data->10</td>
    <td class="has-text-align-center" data->32</td>
    <td class="has-text-align-center" data->13.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->18</td>
    <td>Bulharsko</td>
    <td class="has-text-align-center" data->9</td>
    <td class="has-text-align-center" data->38</td>
    <td class="has-text-align-center" data->13.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->19</td>
    <td>Nizozemsko</td>
    <td class="has-text-align-center" data->9</td>
    <td class="has-text-align-center" data->27</td>
    <td class="has-text-align-center" data->12.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->20</td>
    <td>Chorvatsko</td>
    <td class="has-text-align-center" data->8</td>
    <td class="has-text-align-center" data->30</td>
    <td class="has-text-align-center" data->21.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->21</td>
    <td>&Scaron;v&eacute;dsko</td>
    <td class="has-text-align-center" data->6</td>
    <td class="has-text-align-center" data->22</td>
    <td class="has-text-align-center" data->12.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->22</td>
    <td>D&aacute;nsko</td>
    <td class="has-text-align-center" data->6</td>
    <td class="has-text-align-center" data->25</td>
    <td class="has-text-align-center" data->11.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->23</td>
    <td>Norsko</td>
    <td class="has-text-align-center" data->6</td>
    <td class="has-text-align-center" data->23</td>
    <td class="has-text-align-center" data->12.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->24</td>
    <td>Finsko</td>
    <td class="has-text-align-center" data->6</td>
    <td class="has-text-align-center" data->26</td>
    <td class="has-text-align-center" data->12.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->25</td>
    <td>Loty&scaron;sko</td>
    <td class="has-text-align-center" data->5</td>
    <td class="has-text-align-center" data->20</td>
    <td class="has-text-align-center" data->12.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->26</td>
    <td>Estonsko</td>
    <td class="has-text-align-center" data->5</td>
    <td class="has-text-align-center" data->19</td>
    <td class="has-text-align-center" data->13.03.</td>
    </tr>
    <tr>
    <td class="has-text-align-center" data->27</td>
    <td>Litva</td>
    <td class="has-text-align-center" data->4</td>
    <td class="has-text-align-center" data->13</td>
    <td class="has-text-align-center" data->13.03.</td>
    </tr>
    </tbody>
    </table>
    </figure>
    <hr /><h2>Zdroj dat a dal&scaron;&iacute; metadata</h2>
    <p>Hlavn&iacute;m zdrojem dat je <a href="https://trends.google.com/">Google Trends</a>, ze kter&eacute;ho &uacute;daje o z&aacute;jmu vyhled&aacute;v&aacute;n&iacute; na t&eacute;ma koronavirus poch&aacute;z&iacute;. V&scaron;echna z&iacute;skan&aacute; data jsou normalizov&aacute;na, relativn&iacute; a vz&aacute;jemně porovnateln&aacute; (při z&iacute;sk&aacute;v&aacute;n&iacute; byla postavena proti sobě). Data o vyhled&aacute;v&aacute;n&iacute; byla za obdob&iacute; 21.&nbsp;1.&nbsp;2020 až 24.&nbsp;3.&nbsp;z&iacute;sk&aacute;na k&nbsp;25.&nbsp;3.&nbsp;2020.</p>
    <p>Geometrie sledovan&yacute;ch evropsk&yacute;ch st&aacute;tů byla z&iacute;sk&aacute;na z volně <a href="https://ec.europa.eu/eurostat/web/gisco/geodata/reference-data/administrative-units-statistical-units/countries">dostupn&yacute;ch shapefilů na str&aacute;nk&aacute;ch Eurostatu</a>. Při pln&eacute;m rozli&scaron;en&iacute; odpov&iacute;d&aacute; měř&iacute;tko animovan&eacute; mapy 1&nbsp;:&nbsp;38&nbsp;000&nbsp;000. Použit&yacute;m projekčn&iacute;m souřadnicov&yacute;m syst&eacute;mem je EPSG:54003 (Millerovo zobrazen&iacute;).</p>
    <p>Pro vizualizaci dat jsem použila <a href="https://qgis.org/en/site/">QGIS</a> a jeho plugin <a href="https://www.reddit.com/r/dataisbeautiful/comments/fqmpx5/oc_google_search_interest_in_coronavirus_211_to/">Time Manager</a>. Pro &uacute;pravu tabulek star&yacute; dobr&yacute; Excel. :)</p>
    <p><strong>D&iacute;ky za n&aacute;v&scaron;těvu </strong>blogu! Na <a href="https://www.facebook.com/zemepisec/">Facebooku</a> mohu odpovědět Va&scaron;e dotazy nebo na něm můžete zanechat zpětnou vazbu.</p>

    <hr />
    <p>Napsala Michaela Vojtěchovská, 27. března, 2020. <Link to="/blog/">Zobrazit další články na blogu</Link>.</p>

  </Layout>
)
